export const metaTags = {
  landing: {
    name: 'Ai Powered Education Platform India | Best LMS in India',
    description:
      'Discover The Atomo, an AI-powered education platform and the best LMS in India, offering innovative learning solutions for schools, universities, and businesses. Transform education with cutting-edge technology.',
  },
  useCases: {
    name: 'Smart Class Solutions Providers India | Smart classroom companies in India',
    description:
      'Discover leading Smart Class Solutions providers in India with Atomo&#39;s Atomo. Our innovative smart classroom technologies empower educators and enhance learning experiences through seamless integration and advanced analytics. Transform your educational environment today',
  },
  products: {
    name: 'EdTech Solutions India | AI powered Learning Solutions provider India',
    description:
      'Explore Atomo&#39;s Atomo, the leading provider of AI-powered EdTech solutions in India. Transform education with innovative learning platforms that offer personalized experiences and advanced analytics. Elevate your learning journey with Atomo today',
  },
  company: {
    name: 'Atomo - Leading AI Powered Education Software in India',
    description:
      'Atomo is India&#39;s leading AI powered education software, revolutionizing learning experiences with personalized solutions, advanced analytics, and innovative technology. Discover how Atomo transforms classrooms and empowers educators for the future of education.',
  },
  blogs: {
    name: 'Atomo: The Leading AI-Powered Education Software Revolutionizing Learning in India',
    description:
      'Atomo is India&#39;s leading AI-powered education software, offering innovative learning solutions to enhance student engagement and streamline teaching processes. Experience the future of education with Atomo',
  },
  hurreyScope: {
    name: 'Revolutionize Education with Hurrey AI: India’s Top AI-Powered Personalized Learning Solution',
    description:
      'Unlock personalized learning with Hurrey AI, India’s premier AI-powered educational solution. Enhance student engagement and customize learning experiences with cutting-edge AI technology.',
  },
  languageLearner: {
    name: 'AI-Powered Vocabulary Builder | Best Online AI Learning Tool for Vocabulary Enhancement',
    description:
      'Boost your vocabulary with our AI-powered vocabulary builder. An innovative online tool designed to enhance your language skills through personalized learning and smart AI-driven techniques.',
  },
  physicsSolver: {
    name: 'Atomo: Best AI-Powered Physics Solver for Accurate and Instant Solutions',
    description:
      'Get accurate and instant solutions with Atomo, the best AI-powered physics solver. Simplify complex physics problems and enhance your learning with advanced AI technology.',
  },
  mathSolver: {
    name: 'Atomo: Best AI-Powered Math Solver for Quick and Accurate Solutions',
    description:
      'Experience fast and precise math problem-solving with Atomo, the best AI- powered math solver. Simplify calculations and improve your math skills with cutting-edge AI technology.',
  },
  reseller: {
    name: 'Become a The Atomo Reseller - Transform Education with AI Solutions',
    description:
      'Partner with The Atomo to revolutionize education through cutting-edge AI-powered LMS. Gain exclusive reseller benefits, marketing support, and lucrative profit margins while making education accessible and personalized.',
  },
  privacyPolicy: {
    name: 'Privacy Policy | Atomo',
    description:
      'Read Atomo’s Privacy Policy to understand how we collect, use, and protect your personal information. Learn more about your rights and data privacy.',
  },
  termsAndConditions: {
    name: 'Terms and Conditions | Atomo',
    description:
      'Explore Atomo’s Terms and Conditions to understand the rules, responsibilities, and policies for using our website and services.',
  },
  education_but_cooler: {
    name: 'Coolest Stories in Education | Atomo Campaigns',
    description:
      'Get inspired by stories of teachers and students transforming classrooms with Atomo. Be part of our journey as we celebrate the coolest moments in education.',
  },
};
