import { Component, Input } from '@angular/core';

@Component({
  selector: 'hurrey-progess',
  standalone: true,
  imports: [],
  templateUrl: './progess.component.html',
  styleUrl: './progess.component.scss'
})
export class ProgessComponent {

  @Input() progress = 0;
  constructor() {}

}
