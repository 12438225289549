import { Injectable } from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import { SnackbarComponent } from '../reusable-components/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor() {}

  // This uses the new custom-element method to add the popup to the DOM.
  private showAsElement(
    message: string,
    snackBarState?: 'general' | 'error' | 'caution'
  ) {
    // Create element
    const snackBarEl: NgElement & WithProperties<SnackbarComponent> =
      document.createElement('snackbar-element') as any;

    // Listen to the close event
    snackBarEl.addEventListener('closed', () => {
      document.body.removeChild(snackBarEl);
      // document.body.style.overflow = 'auto';
    });

    // Set the message
    snackBarEl.message = message;

    if (snackBarState) snackBarEl.snackBarState = snackBarState;

    // Add to the DOM
    document.body.appendChild(snackBarEl);

    // document.body.style.overflow = 'hidden';
  }

  openGeneralSnackbar(message: string) {
    this.showAsElement(message, 'general');
  }

  openErrorSnackbar(message: string) {
    this.showAsElement(message, 'error');
  }

  openCautionSnackbar(message: string) {
    this.showAsElement(message, 'caution');
  }
}
