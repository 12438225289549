import { trigger, state, style, transition, animate } from '@angular/animations';
import { NgClass } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-snackbar',
  standalone: true,
  imports: [NgClass],
  templateUrl: './snackbar.component.html',
  styleUrl: './snackbar.component.scss',
  animations: [
    trigger('state', [
      state(
        'opened',
        style({ transform: 'translateY(0%)', opacity: 1, display: 'flex' })
      ),
      state(
        'void, closed',
        style({ transform: 'translateY(100%)', opacity: 0, display: 'none' })
      ),
      transition('* => *', animate('100ms linear')),
    ]),
  ],
})
export class SnackbarComponent {
  @HostBinding('@state')
  state: 'opened' | 'closed' = 'closed';

  @Input() message!: string;

  @Input() snackBarState: 'general' | 'error' | 'caution' = 'general';

  ngOnInit(): void {
    this.state = 'opened';
    setTimeout(() => {
      this.state = 'closed';
    }, 1500
  );
  }
}
