.black_div {
  height: 150px;
  background: black;
  @media screen and (max-width:767px) {
    display: none;
  }
}

.content{
  max-width: 800px;
}

.content_wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width:1023px) {
      padding: 12px 20px;
  }
  @media screen and (max-width:767px) {
    padding: 12px;
}
}

.spinner_div{
  display: flex;
  justify-content: center;
  align-items: center;

}

.no_content{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  margin: 16px 0px;
}
