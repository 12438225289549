<div class="textarea-container" [class.small]="size === 'small'" [class.large]="size === 'large'">
  <div class="label-container" *ngIf="label">
    <label [for]="inputId">
      {{ label }}
      <span class="required-marker" *ngIf="required">*</span>
    </label>
    <span class="helper-text" *ngIf="helperText">{{ helperText }}</span>
  </div>

  <div class="textarea-wrapper">
    <div class="textarea-input-container">
      <textarea

        [id]="inputId"
        [placeholder]="placeholder"
        [value]="value || ''"
        [disabled]="disabled"
        [readonly]="readonly"
        [attr.maxlength]="maxLength"
        [attr.minlength]="minLength"
        [rows]="rows"
        [class.error-state]="validationState === 'error'"
        [class.warning-state]="validationState === 'warning'"
        [class.success-state]="validationState === 'success'"
        (input)="onInput($event)"
        (blur)="onBlur()">
      </textarea>

      <div class="action-buttons">
        <button
          class="attachment-button"
          type="button"
          [disabled]="disabled"
          (click)="handleAttachment()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"/>
          </svg>
        </button>
      </div>
    </div>
  </div>

  <div class="validation-container">
    <div class="character-count" *ngIf="showCharacterCount && maxLength">
      {{ value!!.length || 0 }}/{{ maxLength }}
    </div>
    <div class="validation-message"
         *ngIf="showValidationMessage"
         [class.error]="validationState === 'error'"
         [class.warning]="validationState === 'warning'"
         [class.success]="validationState === 'success'">
      {{ validationMessage }}
    </div>
  </div>
</div>
