import { Component, Inject, Injector, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ActivatedRoute,
  Data,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs';
import { MetaService } from './shared/services/meta.service';
import { createCustomElement } from '@angular/elements';
import { SnackbarComponent } from './shared/reusable-components/snackbar/snackbar.component';
import { SnackbarService } from './shared/services/snackbar.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  title = 'atomos-site';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private metaService: MetaService,
    injector: Injector,
    private snackbarSrvc_:SnackbarService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      const snackBarElement = createCustomElement(SnackbarComponent, {
        injector,
      });

      if (!customElements.get('snackbar-element')) {
        customElements.define('snackbar-element', snackBarElement);
      }
    }
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
        tap(({ name, description }: Data) => {
          console.log({ name, description });
          this.metaService.updateTitle(name);
          this.metaService.updateDescription(description);
        })
      )
      .subscribe();



  }


}
