import { isPlatformBrowser, NgFor, NgIf } from '@angular/common';
import { Component, Inject, inject, PLATFORM_ID } from '@angular/core';
import {
  AtomoInputComponent,
  ValidationState,
} from '../../../shared/reusable-components/atomo-input/atomo-input.component';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NumbersOnlyDirective } from '../../../shared/directives/numbers-only.directive';
import { BackendService } from '../../../shared/services/backend.service';
import {
  CampaignForm,
  FavMemory,
} from '../../../shared/models/campaign_form.model';
import { Router } from '@angular/router';
import { ProgessComponent } from '../../../shared/reusable-components/progess/progess.component';
import { SnackbarService } from '../../../shared/services/snackbar.service';
import { AtomoTextareaComponent } from '../../../shared/reusable-components/atomo-textarea/atomo-textarea.component';
import {
  getDownloadURL,
  ref,
  Storage,
  StorageReference,
  uploadBytesResumable,
} from '@angular/fire/storage';

@Component({
  selector: 'campaign-form',
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    AtomoInputComponent,
    ReactiveFormsModule,
    NumbersOnlyDirective,
    FormsModule,
    ProgessComponent,
    AtomoTextareaComponent,
  ],
  templateUrl: './campaign-form.component.html',
  styleUrl: './campaign-form.component.scss',
})
export class CampaignFormComponent {
  private backendSrvc_: BackendService = inject(BackendService);
  private router: Router = inject(Router);
  addingDoc: boolean = false;

  private snackbarSrvc_: SnackbarService = inject(SnackbarService);

  favTeacherFiles: any[] = [];
  coolestMemoryFiles: any[] = [];

  selected_role: string = '';

  private fireStorage: Storage = inject(Storage);

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  campaignForm: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    phone: new FormControl('', [
      Validators.required,
      Validators.minLength(10),
      Validators.maxLength(10),
    ]),
    age: new FormControl('', [Validators.required]),
    school_name: new FormControl('', [Validators.required]),
    school_city: new FormControl('', [Validators.required]),
    fav_teacher: new FormControl('', Validators.required),
    classroom_memory: new FormControl(''),
  });

  othersFormControl: FormControl = new FormControl(
    { value: '', disabled: false },
    [Validators.required]
  );

  roles: { role: string; name: string }[] = [
    { role: 'admin', name: 'Administrator' },
    { role: 'teacher', name: 'Teacher' },
    { role: 'student', name: 'Student' },
    { role: 'parent', name: 'Parent' },
    { role: 'others', name: 'Other' },
  ];

  handleCheckboxChange(selectedRole: { role: string; name: string }): void {
    this.selected_role = selectedRole.role;

    if (this.selected_role === 'others') {
      this.othersFormControl.enable();
    } else {
      this.othersFormControl.reset();
      this.othersFormControl.disable();
    }
  }

  getOthersValidationState(): ValidationState {
    if (this.othersFormControl.touched) {
      return this.othersFormControl.errors ? 'error' : 'success';
    }
    return null;
  }

  getOthersErrorMessage(): string {
    if (this.othersFormControl.errors && this.othersFormControl.touched) {
      if (this.othersFormControl.errors['required']) {
        return 'This field is required';
      }
    }
    return '';
  }

  getValidationState(controlName: string): ValidationState {
    const control = this.campaignForm.get(controlName);
    if (control?.touched) {
      return control.errors ? 'error' : 'success';
    }
    return null;
  }

  getControls(name: string) {
    const control = this.campaignForm.get(name);
    return control as FormControl;
  }

  getErrorMessage(controlName: string, fieldName?: string): string {
    const control = this.campaignForm.get(controlName);
    if (control?.errors && control.touched) {
      if (control.errors['required']) {
        if (fieldName) {
          return `${fieldName} is required`;
        }
        return 'This field is required';
      }
      if (control.errors['email']) {
        return 'Please enter a valid email';
      }
      if (control.errors['minlength']) {
        return `Minimum length is ${control.errors['minlength'].requiredLength}`;
      }
      if (control.errors['minlength']) {
        return `Maximum length is ${control.errors['minlength'].requiredLength}`;
      }
    }
    return '';
  }

  get submitDisabledCheck(): boolean {
    if (this.selected_role === '') return true;

    if (this.selected_role === 'others') {
      return this.campaignForm.invalid || this.othersFormControl.invalid;
    }

    return this.campaignForm.invalid;
  }

  fileBrowseHandler(event: Event, fileType: string) {
    const input = event.target as HTMLInputElement;
    const files = input.files;
    if (files && files.length > 0) {
      // Ensure at least one file is selected
      const file = files[0]; // Take only the first file
      if (fileType === 'favTeacher') {
        this.prepareFilesList([file], this.favTeacherFiles); // Add the single file to favTeacherFiles
        this.uploadFilesSimulator(0, 'favTeacher'); // Start upload simulation for favTeacherFiles
      } else if (fileType === 'coolestMemory') {
        this.prepareFilesList([file], this.coolestMemoryFiles); // Add the single file to coolestMemoryFiles
        this.uploadFilesSimulator(0, 'coolestMemory'); // Start upload simulation for coolestMemoryFiles
      }
    }
  }

  deleteFile(index: number, fileType: string) {
    if (
      fileType === 'favTeacher' &&
      this.favTeacherFiles[index].progress < 100
    ) {
      console.log('Upload in progress.');
      return;
    } else if (
      fileType === 'coolestMemory' &&
      this.coolestMemoryFiles[index].progress < 100
    ) {
      console.log('Upload in progress.');
      return;
    }

    if (fileType === 'favTeacher') {
      this.favTeacherFiles.splice(index, 1);
    } else if (fileType === 'coolestMemory') {
      this.coolestMemoryFiles.splice(index, 1);
    }
  }

  uploadFilesSimulator(index: number, fileType: string) {
    const targetArray =
      fileType === 'favTeacher'
        ? this.favTeacherFiles
        : this.coolestMemoryFiles;

    setTimeout(() => {
      if (index === targetArray.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (targetArray[index].progress >= 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1, fileType);
          } else {
            targetArray[index].progress += 25;
          }
        }, 200);
      }
    }, 800);
  }

  prepareFilesList(files: any[], targetArray: any[]) {
    const errorFileNames = [];
    for (const item of files) {
      const ifExists = targetArray.filter((a) => a.name === item.name);
      if (ifExists.length === 0) {
        item.progress = 0;
        if (item.size <= 5 * 1024 * 1024) {
          // 5MB max size
          const reader = new FileReader();
          reader.onload = (event: any) => {
            item.dataUri = event.target.result;
          };
          reader.readAsDataURL(item);
          targetArray.length = 0; // Clear any existing files
          targetArray.push(item); // Add the new file
        } else {
          errorFileNames.push(item.name);
        }
      } else {
        if (isPlatformBrowser(this.platformId)) {
          this.snackbarSrvc_.openErrorSnackbar(
            item.name + ' already uploaded!'
          );
        }
      }
    }

    if (errorFileNames.length > 0) {
      if (isPlatformBrowser(this.platformId)) {
        this.snackbarSrvc_.openErrorSnackbar(
          'Please select files less than 5MB for ' + errorFileNames.join(', ')
        );
      }
    }
  }

  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadVideoToStorage(type: 'favTeacher' | 'classMemories', file: File) {
    const path: string = `atomo_campaign/${type}/${new Date().getTime()}`;
    let storageRef: StorageReference = ref(this.fireStorage, path);
    let videoUploadTask = uploadBytesResumable(storageRef, file);
    return videoUploadTask;
  }

  async onSubmit() {
    if (this.addingDoc) return; // Prevent multiple submissions
    this.addingDoc = true;

    try {
      const favTeacher = await this.prepareMemory(
        'fav_teacher',
        this.favTeacherFiles,
        'favTeacher'
      );
      const coolestMemory = await this.prepareMemory(
        'classroom_memory',
        this.coolestMemoryFiles,
        'classMemories'
      );

      const campaignForm: CampaignForm = {
        name: this.getControls('name').value.trim().toLowerCase(),
        email: this.getControls('email').value.trim(),
        phone: this.getControls('phone').value.trim(),
        age: +this.getControls('age').value,
        school_name: this.getControls('school_name').value.trim().toLowerCase(),
        school_city: this.getControls('school_city').value.trim().toLowerCase(),
        fav_teacher: favTeacher,
        fav_class_memory: coolestMemory,
        role:
          this.selected_role === 'others'
            ? this.othersFormControl.value.trim().toLowerCase()
            : this.selected_role,
        created_at: new Date(),
      };

      await this.backendSrvc_.addCampaignForm(campaignForm);
      if (isPlatformBrowser(this.platformId)) {
        this.snackbarSrvc_.openGeneralSnackbar(
          'Response submitted successfully!!'
        );
      }
      this.router.navigate(['/']);
    } catch (error) {
      console.error('Submission failed:', error);
      if (isPlatformBrowser(this.platformId)) {
        this.snackbarSrvc_.openErrorSnackbar(
          'Error while submitting the response'
        );
      }
    } finally {
      this.addingDoc = false;
    }
  }

  private async prepareMemory(
    controlName: string,
    files: File[],
    storageFolder: 'favTeacher' | 'classMemories'
  ): Promise<FavMemory> {
    const memory: FavMemory = {
      description: this.getControls(controlName).value.trim().toLowerCase(),
      video: '',
    };

    if (files.length) {
      try {
        const task = await this.uploadVideoToStorage(storageFolder, files[0]);
        memory.video = await getDownloadURL(task.ref);
      } catch (error) {
        // if (isPlatformBrowser(this.platformId)) {
        //   this.snackbarSrvc_.openGeneralSnackbar(
        //     'Response submitted successfully!!'
        //   );
        // }
      }
    }

    return memory;
  }
}
