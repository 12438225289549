<div class="flex flex-col gap-4 md:gap-6 lg:gap-8 py-8 md:py-12 lg:py-16 px-4 md:px-14 lg:px-[108px] bg-sky_lightest">
  <div class="flex flex-col gap-2 mb-2">
    <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 text-purple_100"> Become Part of the Cool Revolution
    </p>
    <p class="text-hurrey-16 text-black break-words md:w-[70%]">Join us in creating the future of education. Share your
      stories and memories. Help us recognize the teachers and classrooms that made a difference.</p>
  </div>





  <form class=" grid grid-cols-2 gap-3 md:gap-5 lg:gap-6" [formGroup]="campaignForm">

    <div class="col-span-2 md:col-span-1 flex flex-col gap-2">
      <atomo-input formControlName="name" type="text" [required]="true" [placeholder]="'Enter your name'" label="Name"
        [validationState]="getValidationState('name')"
        [validationMessage]="getErrorMessage('name','Name')"></atomo-input>
    </div>

    <div class="col-span-2 md:col-span-1 flex flex-col gap-2">
      <atomo-input formControlName="email" type="email" [required]="true" [placeholder]="'Enter your mail address'"
        label="Email" [validationState]="getValidationState('email')"
        [validationMessage]="getErrorMessage('email','Email ID')"></atomo-input>
    </div>

    <div class="col-span-2 md:col-span-1 flex flex-col gap-2">
      <atomo-input numbersOnly [maxInputLength]="3" formControlName="age" type="text" [required]="true"
        [placeholder]="'Enter your age'" label="Age" [validationState]="getValidationState('age')"
        [validationMessage]="getErrorMessage('age','Age')"></atomo-input>
    </div>

    <div class="col-span-2 md:col-span-1 flex flex-col gap-2">
      <atomo-input numbersOnly formControlName="phone" type="tel" [required]="true"
        [placeholder]="'Enter 10 digit mobile number'" label="Phone Number"
        [validationState]="getValidationState('phone')" [validationMessage]="getErrorMessage('phone','Phone Number')"
        prefix="+91"></atomo-input>
    </div>

    <div class="col-span-2 md:col-span-1 flex flex-col gap-2">
      <atomo-input formControlName="school_name" type="text" [required]="true" [placeholder]="'Enter your school name'"
        label="What was/is your School Name?" [validationState]="getValidationState('school_name')"
        [validationMessage]="getErrorMessage('school_name','School Name')"></atomo-input>
    </div>


    <div class="col-span-2 md:col-span-1 flex flex-col gap-2">
      <atomo-input formControlName="school_city" type="text" [required]="true" [placeholder]="'Enter your school city'"
        label="School City" [validationState]="getValidationState('school_city')"
        [validationMessage]="getErrorMessage('school_city','School City')"></atomo-input>
    </div>


    <div class="col-span-2 md:col-span-1 flex flex-col gap-1">
      <atomo-textarea ngSkipHydration formControlName="fav_teacher" type="text" [required]="true"
        [placeholder]="'Name or Brief Description'" label="Teacher Who Made Education Cool for You"
        [validationState]="getValidationState('fav_teacher')" [validationMessage]="getErrorMessage('fav_teacher')"
        (onAttachment)="fileDropRef.click()"></atomo-textarea>

      <input type="file" #fileDropRef id="fileDropRef" accept="video/*"
        (change)="fileBrowseHandler($event,'favTeacher')" />



      <div class="files-list">
        <div class="single-file" *ngFor="let file of favTeacherFiles; let i = index">
          <img src="../../../../assets/icons/file.svg" width="32" alt="file">
          <div class="info">
            <h4 class="name">
              {{ file?.name }}
            </h4>
            <p class="size">
              {{ formatBytes(file?.size) }}
            </p>
            <hurrey-progess [progress]="file?.progress"></hurrey-progess>
          </div>
          <img src="../../../../assets/icons/delete_red.svg" class="delete" width="20" alt="file"
            (click)="deleteFile(i,'favTeacher')">
        </div>
      </div>
    </div>





    <div class="col-span-2 md:col-span-1 flex flex-col gap-1">
      <atomo-textarea ngSkipHydration formControlName="classroom_memory" type="text"
        [placeholder]="'Enter your favourite memory'" label="Your Coolest Classroom Memory"
        (onAttachment)="fileDropRef1.click()"></atomo-textarea>
      <input type="file" #fileDropRef1 id="fileDropRef1" accept="video/*"
        (change)="fileBrowseHandler($event,'coolestMemory')" />



      <div class="files-list">
        <div class="single-file" *ngFor="let file of coolestMemoryFiles; let i = index">
          <img src="../../../../assets/icons/file.svg" width="32" alt="file">
          <div class="info">
            <h4 class="name">
              {{ file?.name }}
            </h4>
            <p class="size">
              {{ formatBytes(file?.size) }}
            </p>
            <hurrey-progess [progress]="file?.progress"></hurrey-progess>
          </div>
          <img src="../../../../assets/icons/delete_red.svg" class="delete" width="20" alt="file"
            (click)="deleteFile(i,'coolestMemory')">
        </div>
      </div>
    </div>

  </form>

  <div class="flex flex-col gap-4">
    <div class="flex gap-2">
      <p class="text-hurrey-16">Which role describes you best</p>
      <span class="text-rose_80">*</span>
    </div>
    <div class="grid grid-cols-2 gap-4">
      <div class="col-span-2 md:col-span-1 gap-2 checkbox_container" *ngFor="let role of roles">

        <input type="radio" [id]="role.role" [name]="role.name" [value]="role.role" class="checkbox-input"
          [checked]="selected_role === role.role" [(ngModel)]="selected_role" (change)="handleCheckboxChange(role)">

        <label [for]="role.role" class="text-hurrey-16 text-grey">
          {{ role.name }}
        </label>
      </div>
      <div class="col-span-2 md:col-span-1"></div>

      <div class="col-span-2 md:col-span-1">
        <atomo-input *ngIf="selected_role === 'others'" [required]="true" [placeholder]="'Specify your Role'"
          [formControl]="othersFormControl" label="Role" [type]="'text'" [validationState]="getOthersValidationState()"
          [validationMessage]="getOthersErrorMessage()">
        </atomo-input>
      </div>

      <!-- <div class="col-span-2 dropZone" fileDnd (fileDropped)="onFileDropped($event)">

        <input type="file" #fileDropRef id="fileDropRef" multiple accept="video/*"
          (change)="fileBrowseHandler($event)" />
          <div class="flex items-center gap-1">
            <div class="flex justify-center items-center p-1">
              <img src="../../../../assets/icons/video.svg" alt="video-content" width="20">
            </div>
          </div>
        <h4 class="text-neutral_500">Drag and drop file here(upto 5mb)</h4>
        <h4 class="text-neutral_500">or</h4>
        <label for="fileDropRef">Browse for file</label>

      </div>

      <div class="flex justify-end items-center" *ngIf="files.length>0">
        <p class="cursor-pointer text-rose_600 font-bold underline text-hurrey-12 md:text-hurrey-16 lg:text-hurrey-20" (click)="files=[]">Clear All</p>
      </div>

      <div class="files-list">
        <div class="single-file" *ngFor="let file of files; let i = index">
          <img src="../../../assets/icons/file.svg" width="45px" alt="file">
          <div class="info">
            <h4 class="name">
              {{ file?.name }}
            </h4>
            <p class="size">
              {{ formatBytes(file?.size) }}
            </p>
            <hurrey-progess [progress]="file?.progress"></hurrey-progess>
          </div>
          <img src="../../../assets/icons/delete_red.svg" class="delete" width="20px" alt="file" (click)="deleteFile(i)">
        </div>
      </div> -->
    </div>
  </div>



  <div class="grid grid-cols-4 gap-4 md:gap-10">
    <div class="col-span-4 md:col-span-3">
      <p class="text-grey text-hurrey-16"> Verify all information is correct before submitting.</p>
    </div>
    <div class="col-span-4 md:col-span-1 flex items-center justify-center md:items-end md:justify-end">
      <button class="text-hurrey-16 text-white submit_button" (click)="onSubmit()" [disabled]="submitDisabledCheck">
        <span *ngIf="!addingDoc">👉 Join the Cool Revolution!</span>
        <img *ngIf="addingDoc" src="../../../../assets/icons/spinner.svg" alt="loading" height="24" width="24">
      </button>
    </div>
  </div>


</div>



<!-- <div class="flex justify-start items-center" *ngIf="favTeacherFiles.length>0">
        <p class="cursor-pointer text-rose_600 font-bold underline text-hurrey-12" (click)="favTeacherFiles=[]">Clear
          All</p>
      </div> -->
