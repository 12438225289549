import { NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

export type TextAreaSize = 'small' | 'medium' | 'large';
export type ValidationStateTextArea = 'success' | 'error' | 'warning' | null;

@Component({
  selector: 'atomo-textarea',
  standalone: true,
  imports: [NgIf],
  templateUrl: './atomo-textarea.component.html',
  styleUrl: './atomo-textarea.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AtomoTextareaComponent),
      multi: true,
    },
  ],
})
export class AtomoTextareaComponent {
  @Input() label?: string;
  @Input() required: boolean = false;
  @Input() placeholder: string = '';
  @Input() readonly: boolean = false;
  @Input() helperText?: string;
  @Input() size: TextAreaSize = 'medium';
  @Input() maxLength?: number;
  @Input() minLength?: number;
  @Input() validationState: ValidationStateTextArea = null;
  @Input() validationMessage?: string;
  @Input() showCharacterCount: boolean = false;
  @Input() rows: number = 3;
  @Input() autosize: boolean = false;
  @Input() ctaButtonText: string = 'Submit';
  @Input() ctaButtonDisabled: boolean = false;

  @Output() onValueChange = new EventEmitter<string>();
  @Output() onButtonClick = new EventEmitter<string>();
  @Output() onAttachment = new EventEmitter<void>();

  handleAttachment(): void {
    this.onAttachment.emit();
  }

  // Component state
  value: string = '';
  disabled: boolean = false;
  touched: boolean = false;
  inputId: string = `textarea-${Math.random().toString(36).substr(2, 9)}`;

  // ControlValueAccessor methods
  private onChange: (value: string) => void = () => {};
  private onTouched: () => void = () => {};

  get showValidationMessage(): boolean {
    return (
      this.touched &&
      !!this.validationMessage &&
      this.validationState === 'error'
    );
  }

  writeValue(value: string): void {
    this.value = value || '';
    if (this.autosize) {
      setTimeout(() => this.adjustTextareaHeight());
    }
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInput(event: Event): void {
    const textarea = event.target as HTMLTextAreaElement;
    this.value = textarea.value;
    this.onChange(this.value);
    this.onValueChange.emit(this.value);

    if (this.autosize) {
      this.adjustTextareaHeight();
    }
  }

  onBlur(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched();
    }
  }

  handleButtonClick(): void {
    this.onButtonClick.emit(this.value);
  }

  private adjustTextareaHeight(): void {
    const textarea = document.getElementById(
      this.inputId
    ) as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }
}
