<header id="atomo-header">
  <div class="hidden md:block">
    <div class="header_wrapper_md relative" clickOutside (clickOutside)="onOutSideClick($event)">
      <div class="logo cursor-pointer" [routerLink]="['/']">
        <img ngSrc="../../../assets/images/atomos_logo_white.webp" alt="atomos logo" height="28" width="84" priority>
      </div>

      <div class="flex items-center gap-6">
        <!-- <header-button (headerBtnClick)="onHeaderItemClick('products')"
          [isDropdownOpen]="selectedItem == 'products' && isDropDownOpen">Products</header-button> -->
        <p class="text-hurrey-12 text-white cursor-pointer" routerLink="/products" id="products">Products </p>

        <!-- <header-button (headerBtnClick)="onHeaderItemClick('cases')"
          [isDropdownOpen]="selectedItem == 'cases' && isDropDownOpen">Use
          Cases</header-button> -->
        <p class="text-hurrey-12 text-white cursor-pointer" routerLink="/use-cases" id="use-cases">Use Cases </p>
        <!-- <header-button (headerBtnClick)="onHeaderItemClick('pricing')"
          [isDropdownOpen]="selectedItem == 'pricing' && isDropDownOpen">Pricing</header-button> -->
          <p class="text-hurrey-12 text-white cursor-pointer" routerLink="/blogs" id="contact-us">Blogs</p>
        <!-- <p class="text-hurrey-12 text-white cursor-pointer" routerLink="/join-us" id="contact-us">Contact Us</p> -->
        <!-- <header-button (headerBtnClick)="onHeaderItemClick('company')"
          [isDropdownOpen]="selectedItem == 'company' && isDropDownOpen">Company</header-button> -->
        <p class="text-hurrey-12 text-white cursor-pointer" routerLink="/company" id="company">About Us</p>

      </div>

      <div class="get_started">
        <a type="button" class="get_started_button cursor-pointer" id="download-button" routerLink="/reseller">Become a Reseller</a>
      </div>
    </div>
    <div class="grid grid-cols-3 gap-2 bg-black dropdown_items absolute top-16"
      *ngIf="selectedItem == 'products' && isDropDownOpen">
      <div
        class="col-span-1 row-span-2 bg-white rounded-sm dropdown_item main self-stretch flex flex-col gap-2 py-2 px-2 main_products">
        <p class="text-white text-hurrey-12">Products</p>
        <p class="text-hurrey-12 lg:text-hurrey-16 text-white">Browse our premium <br> selection</p>
        <button class="get_started_button w-full">More</button>
      </div>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Hurrey Scope</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Maths Solver</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Physics Solver</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Language Learner</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item text-grey">Meera <br> Coming Soon</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item text-grey">Aperios <br> Coming Soon</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Odyo</button>
    </div>
    <div class="grid grid-cols-3 gap-2 bg-black dropdown_items absolute top-16"
      *ngIf="selectedItem == 'cases' && isDropDownOpen">
      <div
        class="col-span-1 row-span-2 bg-white rounded-sm dropdown_item main self-stretch flex flex-col gap-2 py-2 px-2 main_products">
        <p class="text-white text-hurrey-12">Use Cases</p>
        <p class="text-hurrey-12 lg:text-hurrey-16 text-white">Unleashing Innovation <br> through our platform</p>
        <button class="get_started_button w-full">More</button>
      </div>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Government & <br> Administration</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Schools</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">CSR Funders</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Teachers</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Check Eligibilty</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Student</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Parents</button>
    </div>
    <div class="grid grid-cols-3 gap-2 bg-black dropdown_items absolute top-16"
      *ngIf="selectedItem == 'pricing' && isDropDownOpen">
      <div class="col-span-1 flex flex-col gap-3 p-4 bg-white rounded-sm dropdown_item pricing">
        <p class="text-hurrey-16">Freebie</p>
        <p class="text-hurrey-12 text-grey">Ideal for individuals who need advanced features and tools.</p>
        <p class="flex gap-2 items-center"><strong class="text-hurrey-28 lg:text-hurrey-36">$0 </strong><span
            class="text-hurrey-12 text-grey">/
            Month</span></p>
        <button class="get_started_button w-full pricing">Get Started Now</button>
      </div>
      <div class="col-span-1 flex flex-col gap-3 p-4 bg-white rounded-sm dropdown_item pricing">
        <p class="text-hurrey-16">Freebie</p>
        <p class="text-hurrey-12 text-grey">Ideal for individuals who need advanced features and tools.</p>
        <p class="flex gap-2 items-center"><strong class="text-hurrey-28 lg:text-hurrey-36">$0 </strong><span
            class="text-hurrey-12 text-grey">/
            Month</span></p>
        <button class="get_started_button w-full pricing">Get Started Now</button>
      </div>
      <div class="col-span-1 flex flex-col gap-3 p-4 bg-white rounded-sm dropdown_item pricing">
        <p class="text-hurrey-16">Freebie</p>
        <p class="text-hurrey-12 text-grey">Ideal for individuals who need advanced features and tools.</p>
        <p class="flex gap-2 items-center"><strong class="text-hurrey-28 lg:text-hurrey-36">$0 </strong><span
            class="text-hurrey-12 text-grey">/
            Month</span></p>
        <button class="get_started_button w-full pricing">Get Started Now</button>
      </div>
    </div>
    <div class="grid grid-cols-3 gap-2 bg-black dropdown_items absolute top-16"
      *ngIf="selectedItem == 'company' && isDropDownOpen">
      <div
        class="col-span-1 row-span-2 bg-white rounded-sm dropdown_item main self-stretch flex flex-col gap-2 py-2 px-2 main_products">
        <p class="text-white text-hurrey-12">About Us</p>
        <p class="text-hurrey-12 lg:text-hurrey-16 text-white">It's where the magic <br> happens</p>
        <button class="get_started_button w-full">More</button>
      </div>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">About</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Our Vision</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Our Genesis</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Our Team</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Get in Touch</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Who we are</button>
      <button class="col-span-1 bg-white rounded-sm dropdown_item">Contact us</button>
    </div>
  </div>

  <div class="w-full md:hidden relative">
    <div class="header_wrapper">
      <img ngSrc="../../../assets/images/atomos_logo_white.webp" alt="atomos logo" height="28" width="84" priority
        routerLink="/">
      <img ngSrc="../../../assets/icons/hamburger.svg" alt="expand" height="24" width="24"
        (click)="isDropdownMobileOpened = true">
    </div>
    <div class="dropdown_mobile absolute top-0 bottom-0 left-0 right-0 p-4" *ngIf="isDropdownMobileOpened"
      [@fadeInExpandOnEnter] [@fadeOutCollapseOnLeave]>
      <div class="dropdown_mobile_head">
        <img class="cursor-pointer" ngSrc="../../../assets/images/atomos_logo_black.webp" alt="atomos logo" height="34"
          width="84" priority routerLink="/" (click)="isDropdownMobileOpened=false">
        <img class="cursor-pointer" ngSrc="../../../assets/icons/header_close.svg" alt="close" height="24" width="24"
          (click)="isDropdownMobileOpened=false">
      </div>
      <div class="dropdown_mobile_items">
        <div class="dropdown_mobile_item" (click)="onItemClickMobile('products')">
          <div class="dropdown_mobile_item_head">
            <p class="text-hurrey-16 font-bold" routerLink="/products" id="products">Products</p>
            <!-- <img [ngClass]="{'opened': isListOpen && selectedItemMobile == 'products'}"
              src="../../../assets/icons/chev_right_black.svg" alt="show_less" height="24" width="24"> -->
          </div>
          <!-- <div class="dropdown_mobile_item_content" *ngIf="isListOpen && selectedItemMobile == 'products'"
            [@expandOnEnter] [@collapseOnLeave]>
            <p class="text-hurrey-16">Hurrey Scope</p>
            <p class="text-hurrey-16">Maths Solver</p>
            <p class="text-hurrey-16">Physics Solver</p>
            <p class="text-hurrey-16">Language Learner</p>
            <p class="text-hurrey-16">Odyo</p>
            <p class="text-hurrey-16 text-grey">Meera (Coming Soon)</p>
            <p class="text-hurrey-16 text-grey">Apeiros (Coming Soon)</p>
          </div> -->
        </div>

        <!-- <p class="text-hurrey-16 font-bold" routerLink="/products">Products</p> -->

        <div class="dropdown_mobile_item" (click)="onItemClickMobile('cases')">
          <div class="dropdown_mobile_item_head">
            <p class="text-hurrey-16 font-bold" routerLink="/use-cases" id="usecases">Use Cases</p>
            <!-- <img [ngClass]="{'opened': isListOpen && selectedItemMobile == 'cases'}"
              src="../../../assets/icons/chev_right_black.svg" alt="show_less" height="24" width="24"> -->
          </div>
          <!-- <div class="dropdown_mobile_item_content" *ngIf="isListOpen && selectedItemMobile == 'cases'" [@expandOnEnter]
            [@collapseOnLeave]>
            <p class="text-hurrey-16">Government & Administration</p>
            <p class="text-hurrey-16">Schools</p>
            <p class="text-hurrey-16">CSR Funders</p>
            <p class="text-hurrey-16">Teachers</p>
            <p class="text-hurrey-16">Students</p>
            <p class="text-hurrey-16">Parents</p>
          </div> -->
        </div>
        <div class="dropdown_mobile_item" (click)="onItemClickMobile('blogs')">
          <div class="dropdown_mobile_item_head">
            <p class="text-hurrey-16 font-bold" routerLink="/blogs" id="blogs">Blogs</p>
            <!-- <img [ngClass]="{'opened': isListOpen && selectedItemMobile == 'pricing'}"
              src="../../../assets/icons/chev_right_black.svg" alt="show_less" height="24" width="24"> -->
          </div>
          <!-- <div class="dropdown_mobile_item_content" *ngIf="isListOpen && selectedItemMobile == 'pricing'"
            [@expandOnEnter] [@collapseOnLeave]>
            <p class="text-hurrey-16">Freebie</p>
            <p class="text-hurrey-16">Professional</p>
            <p class="text-hurrey-16">Enterprise</p>
          </div> -->
        </div>
        <div class="dropdown_mobile_item" (click)="onItemClickMobile('company')">
          <div class="dropdown_mobile_item_head">
            <p class="text-hurrey-16 font-bold" routerLink="/company" id="about-us">About Us</p>
            <!-- <img [ngClass]="{'opened': isListOpen && selectedItemMobile == 'company'}"
              src="../../../assets/icons/chev_right_black.svg" alt="show_less" height="24" width="24"> -->
          </div>
          <!-- <div class="dropdown_mobile_item_content" *ngIf="isListOpen && selectedItemMobile == 'company'"
            [@expandOnEnter] [@collapseOnLeave]>
            <p class="text-hurrey-16">About</p>
            <p class="text-hurrey-16">Who we are</p>
            <p class="text-hurrey-16">Our Vision</p>
            <p class="text-hurrey-16">Our Genesis</p>
            <p class="text-hurrey-16">Our Team</p>
            <p class="text-hurrey-16 ">Contact Us</p>
          </div> -->
        </div>

        <div class="dropdown_mobile_item" (click)="onItemClickMobile('reseller')">
          <div class="dropdown_mobile_item_head">
            <p class="text-hurrey-16 font-bold" routerLink="/reseller" id="reseller">Become a Reseller</p>
            <!-- <img [ngClass]="{'opened': isListOpen && selectedItemMobile == 'company'}"
              src="../../../assets/icons/chev_right_black.svg" alt="show_less" height="24" width="24"> -->
          </div>
          <!-- <div class="dropdown_mobile_item_content" *ngIf="isListOpen && selectedItemMobile == 'company'"
            [@expandOnEnter] [@collapseOnLeave]>
            <p class="text-hurrey-16">About</p>
            <p class="text-hurrey-16">Who we are</p>
            <p class="text-hurrey-16">Our Vision</p>
            <p class="text-hurrey-16">Our Genesis</p>
            <p class="text-hurrey-16">Our Team</p>
            <p class="text-hurrey-16 ">Contact Us</p>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</header>
