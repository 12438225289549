<section class="campaign_features flex flex-col gap-8 py-8 md:py-16 lg:py-32 px-4 md:pl-14 lg:pl-[108px]">

  <div [@slideInRight]
    class=" flex flex-col items-center  md:items-start md:justify-start gap-6 ">
    <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 text-purple_100 text-center md:text-left" id="our-vision-head"> What's Atomo All
      About ?</p>
    <p class="text-hurrey-16 text-black break-words md:w-[70%] text-center md:text-start" id="atomo_s_vision"> Atomo is transforming education
      with a fresh perspective. From personalized
      learning to bridging accessibility gaps, we are here to make classrooms smarter,
      greener and cooler.</p>
  </div>

  <div class="grid grid-cols-2  md:grid-cols-3 gap-x-4 gap-y-4 lg:gap-x-6 lg:gap-y-12">
    <ng-container *ngFor="let item of features;let i= index">
      <div class="col-span-3 md:col-span-1 flex justify-center md:justify-normal">
        <div class="flex flex-col items-center justify-center md:justify-normal md:items-start gap-1 lg:gap-2">
          <img [src]="item.img" [alt]="item.title" class="icon">
          <p class="lg:text-hurrey-20 text-hurrey-16 text-black" [id]="'atomo-feature'+'-'+i"> {{item.title}}</p>
          <p class="text-grey text-hurrey-16 break-words" style="flex:1 0 0"
            [id]="'atomo-feature'+'-'+i+'-description'">{{item.description}}</p>
        </div>
      </div>
    </ng-container>
  </div>


</section>

<section id="why_participate" class="why_participate py-8 md:py-16 lg:py-32 px-4 md:pl-14 lg:pl-[108px]">
  <div class="backdrop"></div>
  <div [@slideInOut]
    class=" flex flex-col items-start justify-start gap-6">
    <p class="text-hurrey-24 md:text-hurrey-28 lg:text-hurrey-36 text-purple_100" id="our-vision-head"> Why you should
      be part of this Movement ?</p>
    <p class="text-hurrey-16 text-black break-words md:w-[70%]" id="atomo_s_vision"> Learning is not just about books,
      its about memories, experiences and the people
      who make it fun. This is your chance to celebrate the past while being part of the
      future!</p>

  </div>
</section>
